<script setup lang="ts">
import Select from 'primevue/select';
import Button from '~/components/design-system/button.vue';
import Input from '~/components/design-system/form/input.vue';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';

const auth = useAuthStore();
const site = useSiteStore();
const securityQuestions: globalThis.Ref<string[]> = ref();
const securityQuestionChosen: globalThis.Ref<string> = ref();
const securityAnswer: globalThis.Ref<string> = ref('');

onMounted(() => {
  securityQuestions.value = [
    'first dog',
    'What was your first primary school teacher name',
    'Johnathan Cena',
    'Bill Nye the Science Guy',
  ];
});

function inputRegex() {
  //run regex on sec answer if neccessary
}

function goToStepThree() {
  //make api call if correct close modal
  //make api call for chose welcome offer
  // $complianceService.sendSecurityQuestion(securityQuestionChosen.value, securityAnswer.value)
  //.then((data) => {
  //   if (data.isSuccessful) {
  //     //put security question in state?
  //   } else {
  //     //error choosing welcome offer
  //   }
  //   });
  //check if passport or failed get names
  if (auth.getPartialPostReg) {
    //Partial completion
    site.activateModal('registrationPartialCompletion');
  } else {
    //full completion
    site.activateModal('registrationFullCompletion');
  }
}
</script>

<template>
  <div class="flex flex-col flex-wrap justify-center items-center mx-3">
    <p>
      <strong>{{ $t('set-up-security-q') }}</strong>
    </p>
    <Select
      class="w-full mb-2"
      :options="securityQuestions"
      :placeholder="$t('select-security-question')"
      v-model:model-value="securityQuestionChosen"
    />
    <Input
      class="w-full mb-3 welcome-offer"
      v-model:model-value="securityAnswer"
      :label="$t('answer')"
      @update:model-value="inputRegex()"
    />
  </div>

  <div class="w-full dark:bg-dark-900 bg-light-200 flex justify-center">
    <Button
      :label="$t('continue')"
      @click="goToStepThree()"
      class="w-full m-3"
    />
  </div>
</template>

<style scoped lang="scss">
p {
  color: #2b303b;
  .dark & {
    color: #fff;
  }
}
</style>

<style lang="scss">
.p-float-label input.welcome-offer.p-filled ~ label.input-label,
.p-float-label input.welcome-offer:focus ~ label.input-label {
  top: 13px;
}
</style>
